<template>
    <div class="expand-slider-module-container" @dblclick="setSelect()" :style="getBackground">
        <div class="expand-slider-container-inner" v-if="config.panel.length != 0">
            <div class="expand-slider-container">
                <i class="fas fa-chevron-left" @click="index--"/>
                <div v-if="config.panel" class="expand-slider-content">
                    <inline-edit element="h1" :target="config.panel[index].title"/>
                    <inline-edit element="h2" :target="config.panel[index].subTitle"/>
                    <div class="expand-slider-content-panel">
                        <inline-edit element="h3" :target="config.panel[index].textTitle"/>
                        <inline-edit element="p" :target="config.panel[index].text"/>
                    </div>
                    <inline-edit :link="config.panel[index].button.link" element="p" :target="config.panel[index].button.text"/>
                </div>
                <i class="fas fa-chevron-right" @click="index++"/>
            </div>
            <div class="image-dots-container">
                <i
                    v-for="i in config.panel.length" 
                    @click="index = (i-1)" 
                    :key="'index-dot-'+i" 
                    class="fas fa-circle"
                    :style="isFaded(i-1)"
                />
            </div>
        </div>
        <p v-else> Double click...</p>
    </div>
</template>

<script>
      import * as imgManager from "@/store/imgManager";

    export default {
        props: ["config"],
        data(){
            return {
                index: 0,
                haveMarks: false
            }
        },
        computed:{
            getBackground(){
                if(this.config.panel.length != 0){
                    let r = this.config.panel[this.index].rgba.r
                    let g = this.config.panel[this.index].rgba.g
                    let b = this.config.panel[this.index].rgba.b
                    let a = 1.0*(this.config.panel[this.index].rgba.a)/100.0
                    return 'background-image: linear-gradient( rgba('+r+', '+g+', '+b+', '+a+'), rgba('+r+', '+g+', '+b+', '+a+') ), url(\''+
                    this.getImg(this.config.panel[this.index].background)
                    +'\')';
                }else{
                    return "";
                }
            }
        },
        watch: {
            index(){
                if(this.index >= this.config.panel.length){
                    this.index = 0;
                }
                else if(this.index < 0){
                    this.index = this.config.panel.length - 1;
                }
            }
        },
        methods:{
            setSelect(){
                this.$store.state.selectedSlider = this.index;
            },
            isFaded(i){
                if(i == this.index)
                {
                    return "opacity: 100%;";
                }
                return "opacity: 20%;"
            },
            mounted(){
                  if(!this.config.panel){
                        this.config["panel"] = {}
                  }
                  for(let i=0; i < this.config.panel.length; i++){
                        if(!this.config.panel[i].title){
                              this.config.panel[i]["title"] = {value: this.config.panel[i].title}
                        }
                        if(typeof this.config.panel[i].title === 'string'){
                              this.config.panel[i].title = {value: this.config.panel[i].title}
                        }
                        if(!this.config.panel[i].subTitle){
                              this.config.panel[i]["subTitle"] = {value: this.config.panel[i].subTitle}
                        }
                        if(typeof this.config.panel[i].subTitle === 'string'){
                              this.config.panel[i].subTitle = {value: this.config.panel[i].subTitle}
                        }
                        if(!this.config.panel[i].textTitle){
                              this.config.panel[i]["textTitle"] = {value: this.config.panel[i].textTitle}
                        }
                        if(typeof this.config.panel[i].textTitle === 'string'){
                              this.config.panel[i].textTitle = {value: this.config.panel[i].textTitle}
                        }
                        if(!this.config.panel[i].text){
                              this.config.panel[i]["text"] = {value: this.config.panel[i].text}
                        }
                        if(typeof this.config.panel[i].text === 'string'){
                              this.config.panel[i].text= {value: this.config.panel[i].text}
                        }
                        if(this.config.panel[i].button){
                              if(typeof this.config.panel[i].button.text === 'string'){
                                    this.config.panel[i].button.text= {value: this.config.panel[i].button.text}
                              }
                        }

                        if(!this.config.panel[i].rgba){
                              this.config.panel[i]["rgba"] = {}
                              this.config.panel[i].rgba["r"] = 0
                              this.config.panel[i].rgba["g"] = 0
                              this.config.panel[i].rgba["b"] = 0
                              this.config.panel[i].rgba["a"] = 0
                        }
                  }
            }
        }
    }
</script>